.main-button {
  color: white;
  border: none;
  border-radius: 30px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;

  padding-left: 34px;
  padding-right: 34px;
  padding-top: 0.35rem;
  padding-bottom: 0.35rem;

  &.menu-button-max-width {
    max-width: 216px;
    min-width: 216px;
    margin-right: auto;
  }

  // @media (max-width: 768px) {
  //   margin-left: 16px;
  //   margin-right: 16px;
  //   margin-bottom: 9px;
  //   margin-top: 9px;
  // }

  &:hover {
    opacity: 0.8;
    transition: all 0s;
    &.non-reactive {
      opacity: 1;
      cursor: default;
    }
  }

  &.red-gradient {
    background: $cormie-red-gradient;
  }

  &.grey {
    background-color: gray !important;
  }

  &.blue {
    opacity: 1;
    background-color: $cormie-blue;
    &:hover {
      background-color: #028883;
      &.non-reactive {
        background-color: $cormie-blue;
      }
    }

    &.pressed {
      background-color: #028883;
    }

    &.invert {
      font-weight: 700;
      background-color: transparent;
      color: $cormie-blue;
      border: 2px solid $cormie-blue;

      &:hover {
        border: 2px solid #028883;
        color: white;
        background-color: #028883;
      }
    }

    &.faded {
      background-color: $cormie-blue-faded;
      color: #868686;

      &:hover {
        background-color: #078a88;
      }

      &.invert {
        color: #078a88;
        border: 2px solid #078a88
      }
    }

  }

  &.pink {
    opacity: 1;
    background-color: $cormie-pink;
    &:hover {
      background-color: #ee0353;
      &.non-reactive {
        background-color: $cormie-pink !important;
      }
    }

    &.pressed {
      background-color: #ee0353;
    }

    &.invert {
      font-weight: 700;
      background-color: transparent;
      color: $cormie-pink;
      border: 2px solid $cormie-pink;

      &:hover {
        border: 2px solid #ee0353;
        color: white;
        background-color: #ee0353;
      }
    }

    &.faded {
      background-color: $cormie-pink-faded;
      color: #868686;

      &:hover {
        background-color: #e896b1;
        &.non-reactive {
          background-color: $cormie-pink-faded !important;
        }
      }

      &.invert {
        color: #e896b1;
        border: 2px solid #e896b1
      }
    }
  }
}

.cart-button-hover:hover .cart-hover-added,
.cart-button-hover .cart-hover-remove {
  display: none;
}

.cart-button-hover:hover .cart-hover-remove {
  display: inline;
}
