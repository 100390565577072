// Stylesheet for global styles such as fonts, etc

// Fonts
// Imported Monserrat weights:
// 300 ( + Italics)
// 500 ( + Italics) <= Default
// 700 ( + Italics)
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;0,700;1,300;1,500;1,700&display=swap");

// Variables
$cormie-pink: #fe5a92;
$cormie-pink-faded: #F9E4EB;
$cormie-blue: #09adaa;
$cormie-blue-faded: #ECFBFC;
$cormie-red-gradient: transparent linear-gradient(90deg,#ec028b,#f9423a) 0 0 no-repeat padding-box!important;
$cormie-blue-gradient: transparent linear-gradient(90deg,#097ABB,#0DACAA) 0 0 no-repeat padding-box!important;
$background-colour: #f9faf9;
$faded-grey-border: #F4F4F4;

body {
  height: 100vh;
  width: 100vw;
  max-width: 1920px !important;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  background-color: $background-colour;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

h1 {
  font-size: 2rem;
}

i {
  font-weight: 300;
}

a {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    color: inherit !important;
    filter: brightness(0.85);
    text-decoration: underline !important;
    transition: all 0s;
  }

  &.disabled {
    opacity: 0.5;
    &:hover {
      cursor: default;
    }
  }
}

.display-xxxl {
  @media (min-width: 1920px) {
    display: block !important;
  }
}

.hoverable {
  transition: all 0.3s ease;
  &:hover {
    opacity: 0.6;
    color: inherit;
    transition: all 0s;
    cursor: pointer;
  }
}

.col {
  margin: 0;
  padding: 0;
}

.row {
  margin: 0;
  padding: 0;
  max-width: 1140px;
}

button {
  max-width: 570px;
}

.light-text {
  font-weight: 300;
}

.muted-text {
  color: black;
  opacity: 0.5
}

.half-opacity {
  opacity: 0.5
}

.half-muted-text {
  color: black;
  opacity: 0.6
}

.cormie-blue-text {
  color: $cormie-blue;
}

.cormie-pink-text {
  color: $cormie-pink;
}

.red-blue-gradient-icon {
  background: linear-gradient(45deg, $cormie-blue, $cormie-pink);
}

.red-gradient-icon {
  background: $cormie-red-gradient
}

.blue-gradient-icon {
  background: $cormie-blue-gradient
}

.text-overflow-wrap {
  overflow-wrap: anywhere;
}

.credit-card-bullets {
  font-size: 0.5rem;
  letter-spacing: 3px;
}

.cormie-form-control {
  border-radius: 1.25rem;
}

.modal80vw {
  width: 80vw;
  max-width: 1190px;
}

.tag-dropdown-plus {
  color: black;
  background-color: #EEEEEE !important;
  height: 35px !important;
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
}

.tag-add-button-label {
  margin-left: 0.75rem;
}

.is-invalid-manual {
  border: red solid 1px;
}

.limited-dropdown {
  max-height: 29rem;
  overflow: scroll;
  transform: none !important;
}

.global-max-height-220 {
  @media (max-width: 575px) {
    max-height: 220px;
  }
}

.permacart-col-breakpoints > * {
  flex: 0 0 auto;
  width: 100%;

  @media (min-width: 1366px) {
    flex: 0 0 auto;
    width: 50%;
  }

  @media (min-width: 1680px) {
    flex: 0 0 auto;
    width: 33.33%;
  }
}

.padding-left-30 {
  padding-left: 30px !important;
}

.non-table-tags {
  padding: 1rem 1rem 1.1rem 1.3rem !important;
  font-size: 0.9rem !important;
}

.card-img {
  user-select: none;
}

.responsive-table {
  tr td {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  overflow: scroll;

  .hide-sm {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .hide-md {
    @include media-breakpoint-down(md) {
      display:none;
    }
  }

  .hide-lg-xl {
    @include media-breakpoint-up(lg) {
      display:none;
    }
    @include media-breakpoint-up(xl) {
      display:table-cell;
    }
  }

  tfoot {
    tr {
      height: 4rem;

      &.grand-total-row {
        background-color: $cormie-blue-faded;
        border-color: $faded-grey-border;
        border-left: $faded-grey-border solid;
        border-right: $faded-grey-border solid;
      }

      .detail-text {
        position: relative;
        &:before {
          position: absolute;
          left: -14px;
          content: ":";
        }
      }
    }
  }
}

// Scroll margins
#userTags, #deliveryInstructions, #permacart, #userBilling {
  @include media-breakpoint-down(lg) {
    scroll-margin-top: 100px;
  }
  @include media-breakpoint-up(lg) {
    scroll-margin-top: 20px;
  }

}
